:root {
  --fc-border-color: black;
  --fc-daygrid-event-dot-width: 5px;
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-day-background: red !important;
}

.fc-daygrid{background-color: white !important;  }

/* .fc-toolbar{background:#ecf6ff  !important} */ 
/* .fc-day-sat{background:#ffecec  !important} */